//INDEX

.main__container {
    min-height: 200px;
}

// TABS ----------------

.tab__container {
    margin-top: 15px;
    margin-bottom: 25px;
    gap: 20px;

    .button_tab {
        color: var(--brown_primary);
        font-size: 18px;
        border-bottom-width: 2px;
        border-bottom-color: transparent;
    }
}

.active {
    border-bottom-color: var(--brown_primary) !important;
}

// JOB CARD --------------

.job__card {
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    border-width: 2px;
    border-color: #b1afaf;
    margin-bottom: 10px;
    cursor: pointer;

    .job__card__title {
        font-size: 18px;
        margin-bottom: 5px;
    }

    .job__card__items__container {
        display: flex;
        align-items: center;
        gap: 5px;

        .job__card__item {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 5px 10px;
            background-color: #cfcbcb;
            border-radius: 5px;
            .icon {
                margin-bottom: 1px;
            }
        }
    }

    .job__card__button {
        margin-top: 10px;
    }
}

.active__jobcard {
    border-color: var(--brown_primary) !important;
}

// JOB DETAIL -------------

.job__detail__container {
    padding: 10px;
    border-width: 2px;
    border-color: #b1afaf;
    border-radius: 5px;

    .job__detail__title {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .job__detail__paragraph {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .job__detail__paragraph__title {
        font-size: 16px;
        font-weight: 500;
    }
}

// ALL POSITIONS ---------

.all__positions__title {
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 20px;
}
